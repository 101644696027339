<!--<nz-layout class="app-layout">-->
<!--  <nz-sider [nzCollapsed]="true"-->
<!--            [nzTrigger]="null"-->
<!--            class="menu-sidebar">-->

<!--    <div class="sidebar-logo flex flex-column items-center justify-center"-->
<!--         nz-tooltip-->
<!--         nzTooltipPlacement="right"-->
<!--         nzTooltipTitle="Tableau de bord">-->

<!--      <a routerLink="/">-->
<!--        <img alt="logo" ngSrc="assets/AmphiboleCompta.png" height="48" width="48" priority>-->
<!--      </a>-->
<!--    </div>-->

<!--    <ul [nzInlineCollapsed]="true" nz-menu nzMode="inline" nzTheme="dark" class="top-level-ul">-->
<!--      <li>-->
<!--        <ul>-->
<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Chantiers">-->
<!--            <a routerLink="/worksites">-->
<!--              <i nz-icon nzType="fa-twotone:person-digging"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Clients">-->
<!--            <a routerLink="/customers">-->
<!--              <i nz-icon nzType="fa-twotone:users"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Fournisseurs">-->
<!--            <a routerLink="/suppliers">-->
<!--              <i nz-icon nzType="fa-twotone:person-carry-box"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right"-->
<!--              nzTooltipTitle="Matériels et équipements">-->
<!--            <a routerLink="/supplies">-->
<!--              <i nz-icon nzType="fa-twotone:truck"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Éléments">-->
<!--            <a routerLink="/items">-->
<!--              <i nz-icon nzType="fa-twotone:warehouse-full"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Ouvrages">-->
<!--            <a routerLink="/tasks">-->
<!--              <i nz-icon nzType="fa-twotone:toolbox"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Devis">-->
<!--            <a routerLink="/quotes">-->
<!--              <i nz-icon nzType="fa-twotone:euro-sign"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Commandes">-->
<!--            <a routerLink="/orders">-->
<!--              <i nz-icon nzType="fa-twotone:receipt"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Factures émises">-->
<!--            <a routerLink="/invoices">-->
<!--              <i nz-icon nzType="fa-twotone:inbox-out"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Factures reçues">-->
<!--            <a routerLink="/bills">-->
<!--              <i nz-icon nzType="fa-twotone:inbox-in"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Bons de commande">-->
<!--            <a routerLink="/purchase-orders">-->
<!--              <i nz-icon nzType="fa-twotone:cart-shopping"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Notes">-->
<!--            <a routerLink="/notes">-->
<!--              <i nz-icon nzType="fa-twotone:notes"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Mailing">-->
<!--            <a routerLink="/mailing">-->
<!--              <i nz-icon nzType="fa-twotone:paper-plane"></i>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Archives">-->
<!--            <a routerLink="/archives">-->
<!--              <i nz-icon nzType="fa-twotone:box-archive"></i>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </li>-->

<!--      <li>-->
<!--        <ul>-->
<!--          <li nz-menu-item nz-tooltip nzMatchRouter nzTooltipPlacement="right" nzTooltipTitle="Paramètres">-->
<!--            <a routerLink="/settings">-->
<!--              <i nz-icon nzType="fa-twotone:gear"></i>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </nz-sider>-->

<nz-layout class="app-layout">
  <nz-header>
    <div class="app-header flex justify-between content-center items-center px3">
      <div class="flex">
        <a routerLink="/" class="mr2" nz-tooltip nzTooltipPlacement="bottomLeft" nzTooltipTitle="Tableau de bord">
          <img alt="logo" ngSrc="assets/AmphiboleCompta.png" height="48" width="48" priority>
        </a>

        <div class="cursor-pointer header-text">
          <div nz-popover
               [nzPopoverContent]="appMenu"
               nzPopoverPlacement="bottomLeft"
               [(nzPopoverVisible)]="menuVisible">
            <i nz-icon nzType="fa-twotone:grid" class="mr1"></i>
            Menu
          </div>
        </div>
      </div>

      <div class="header-text">
        {{ pageTitle }}
      </div>

      <div>
        <a [nzDropdownMenu]="menu" class="header-menu" nz-dropdown>
          <ng-container>
            <i nz-icon nzType="fa-solid:user"></i>
            &nbsp;
            {{ fullName }}
          </ng-container>
          <i nz-icon nzType="fa-solid:angle-down"></i>
        </a>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <ng-container>
              <li nz-menu-item (click)="myAccount()" nzMatchRouter>
                <i nz-icon nzType="fa-solid:user"></i>
                &nbsp;
                Gérer mon compte ↗
              </li>

              <li nz-menu-item (click)="logout()" nzMatchRouter>
                <i nz-icon nzType="fa-solid:arrow-right-from-bracket"></i>
                &nbsp;
                Se déconnecter
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </nz-header>

  <nz-content class="m2">
    <div class="inner-content p2 h100">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
</nz-layout>
<!--</nz-layout>-->

<ng-template #appMenu>
  <div nz-row [nzGutter]="[16, 16]" [style.width.px]="1000">
    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les partenaires</span>
    </div>

    <!--    <div nz-col [nzSpan]="8">-->
    <!--      <nz-card [nzHoverable]="true" class="h100">-->
    <!--        <div class="w100 h100 flex items-center">-->
    <!--          <i nz-icon nzType="fa-twotone:person-digging" class="mr2"></i>-->
    <!--          <span class="text">Chantiers</span>-->
    <!--        </div>-->
    <!--      </nz-card>-->
    <!--    </div>-->

    <div nz-col [nzSpan]="8">
      <a routerLink="/customers" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:users" class="mr2" [style.color]="'#2980b9'"></i>
            <span class="text">Clients</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/suppliers" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:person-carry-box" class="mr2" [style.color]="'#2980b9'"></i>
            <span class="text">Fournisseurs</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Le catalogue</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/supplies" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:truck" class="mr2" [style.color]="'#f39c12'"></i>
            <span class="text">Matériels et équipements</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/items" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:warehouse-full" class="mr2" [style.color]="'#f39c12'"></i>
            <span class="text">Éléments</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/tasks" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:toolbox" class="mr2" [style.color]="'#f39c12'"></i>
            <span class="text">Ouvrages</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les recettes</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/quotes" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:euro-sign" class="mr2" [style.color]="'#27ae60'"></i>
            <span class="text">Devis</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/orders" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:receipt" class="mr2" [style.color]="'#27ae60'"></i>
            <span class="text">Commandes</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/invoices" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:inbox-out" class="mr2" [style.color]="'#27ae60'"></i>
            <span class="text">Factures émises</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les charges</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/bills" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:inbox-in" class="mr2" [style.color]="'#c0392b'"></i>
            <span class="text">Factures reçues</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/purchase-orders" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:cart-shopping" class="mr2" [style.color]="'#c0392b'"></i>
            <span class="text">Bons de commandes</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les outils</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/mailing" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:paper-plane" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Mailing</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/accounting" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:calculator" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Comptabilité</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/unpaid" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:file-xmark" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Factures impayées</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/notes" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:notes" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Notes</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/archives" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:box-archive" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Archives</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Paramètres</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/settings" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-twotone:gear" class="mr2" [style.color]="'#34495e'"></i>
            <span class="text">Paramètres</span>
          </div>
        </nz-card>
      </a>
    </div>
  </div>
</ng-template>
