import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SupplierTypeModel} from '../models/supplier-type.model';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupplierTypesService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/SupplierTypes/`;
    return await firstValueFrom(this.httpClient.get<Array<SupplierTypeModel>>(url));
  }

  getOne() {
  }

  async postOne(customerType: SupplierTypeModel) {
    const url = `${environment.apiBaseEndpoint}/SupplierTypes/`;
    return await firstValueFrom(this.httpClient.post<SupplierTypeModel>(url, customerType));
  }

  async putOne(customerType: SupplierTypeModel) {
    const url = `${environment.apiBaseEndpoint}/SupplierTypes/`;
    return await firstValueFrom(this.httpClient.put<SupplierTypeModel>(url, customerType));
  }

  async deleteOne(supplierTypeId: number) {
    const url = `${environment.apiBaseEndpoint}/SupplierTypes/${supplierTypeId}/`;
    return await firstValueFrom(this.httpClient.delete(url));
  }
}
