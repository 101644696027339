import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DocumentCategoryModel} from '../models/document-category.model';
import {environment} from '../../environments/environment';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentCategoriesService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/DocumentCategories/`;
    return await firstValueFrom(this.httpClient.get<Array<DocumentCategoryModel>>(url));
  }

  async postOne(documentCategory: DocumentCategoryModel) {
    const url = `${environment.apiBaseEndpoint}/DocumentCategories/`;
    return await firstValueFrom(this.httpClient.post<DocumentCategoryModel>(url, documentCategory));
  }

  async putOne(documentCategory: DocumentCategoryModel) {
    const url = `${environment.apiBaseEndpoint}/DocumentCategories/`;
    return await firstValueFrom(this.httpClient.put<DocumentCategoryModel>(url, documentCategory));
  }

  async deleteOne(documentCategoryId: number) {
    const url = `${environment.apiBaseEndpoint}/DocumentCategories/${documentCategoryId}/`;
    return await firstValueFrom(this.httpClient.delete(url));
  }
}
