import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {QuoteStatusModel} from '../models/quote-status.model';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteStatusesService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/QuoteStatuses/`;
    return await firstValueFrom(this.httpClient.get<Array<QuoteStatusModel>>(url));
  }
}
