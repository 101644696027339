import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CustomerTypeModel} from '../models/customer-type.model';
import {SupplierTypeModel} from '../models/supplier-type.model';
import {UnitModel} from '../models/unit.model';
import {ItemCategoryModel} from '../models/item-category.model';
import {DocumentCategoryModel} from '../models/document-category.model';
import {QuoteStatusModel} from '../models/quote-status.model';
import {SupplyCategoryModel} from '../models/supply-category.model';
import {AppSettingsService} from './app-settings.service';
import {CustomerTypesService} from './customer-types.service';
import {SupplierTypesService} from './supplier-types.service';
import {ItemCategoriesService} from './item-categories.service';
import {SupplyCategoriesService} from './supply-categories.service';
import {DocumentCategoriesService} from './document-categories.service';
import {UnitsService} from './units.service';
import {QuoteStatusesService} from './quote-statuses.service';
import {Title} from '@angular/platform-browser';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import smartLogger from '../misc/smart-logger';
import {PaymentMethodModel} from '../models/payment-method.model';
import {PaymentMethodsService} from './payment-methods.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  username = new BehaviorSubject('');
  pageTitle = new BehaviorSubject('');
  customerTypes = new BehaviorSubject(Array<CustomerTypeModel>());
  supplierTypes = new BehaviorSubject(Array<SupplierTypeModel>());
  units = new BehaviorSubject(Array<UnitModel>());
  itemCategories = new BehaviorSubject(Array<ItemCategoryModel>());
  supplyCategories = new BehaviorSubject(Array<SupplyCategoryModel>());
  documentCategories = new BehaviorSubject(Array<DocumentCategoryModel>());
  quoteStatuses = new BehaviorSubject(Array<QuoteStatusModel>());
  paymentMethods = new BehaviorSubject(Array<PaymentMethodModel>());

  constructor(private settingsService: AppSettingsService,
              private customerTypesService: CustomerTypesService,
              private supplierTypesService: SupplierTypesService,
              private itemCategoriesService: ItemCategoriesService,
              private supplyCategoriesService: SupplyCategoriesService,
              private documentCategoriesService: DocumentCategoriesService,
              private unitsService: UnitsService,
              private quoteStatusesService: QuoteStatusesService,
              private paymentMethodsService: PaymentMethodsService,
              private titleService: Title,
              private notificationService: NzNotificationService,
              private oidcSecurityService: OidcSecurityService) {
  }

  setPageTitle(pageTitle: string) {
    this.pageTitle.next(pageTitle);
  }

  setCustomerTypes(customerTypes: Array<CustomerTypeModel>) {
    this.customerTypes.next(customerTypes);
  }

  setSupplierTypes(supplierTypes: Array<SupplierTypeModel>) {
    this.supplierTypes.next(supplierTypes);
  }

  setUnits(units: Array<UnitModel>) {
    this.units.next(units);
  }

  setItemCategories(itemCategories: Array<ItemCategoryModel>) {
    this.itemCategories.next(itemCategories);
  }

  setSupplyCategories(supplyCategories: Array<SupplyCategoryModel>) {
    this.supplyCategories.next(supplyCategories);
  }

  setDocumentCategories(documentCategories: Array<DocumentCategoryModel>) {
    this.documentCategories.next(documentCategories);
  }

  setQuoteStatuses(quoteStatuses: Array<QuoteStatusModel>) {
    this.quoteStatuses.next(quoteStatuses);
  }

  setPaymentMethods(paymentMethods: Array<PaymentMethodModel>) {
    this.paymentMethods.next(paymentMethods);
  }

  async boot() {
    try {
      this.oidcSecurityService.getUserData().subscribe(value => {
        if (value) {
          this.username.next(value.name);
        }
      });

      const settings = await this.settingsService.getAll();
      const appName = settings.appName ? settings.appName : 'AmphiboleCompta';
      this.titleService.setTitle(appName);

      let customerTypes = await this.customerTypesService.getAll();
      this.setCustomerTypes(customerTypes);

      let supplierTypes = await this.supplierTypesService.getAll();
      this.setSupplierTypes(supplierTypes);

      let itemCategories = await this.itemCategoriesService.getAll();
      this.setItemCategories(itemCategories);

      let supplyCategories = await this.supplyCategoriesService.getAll();
      this.setSupplyCategories(supplyCategories);

      let units = await this.unitsService.getAll();
      this.setUnits(units);

      let documentCategories = await this.documentCategoriesService.getAll();
      this.setDocumentCategories(documentCategories);

      let quoteStatuses = await this.quoteStatusesService.getAll();
      this.setQuoteStatuses(quoteStatuses);

      let paymentMethods = await this.paymentMethodsService.getAll();
      this.setPaymentMethods(paymentMethods);
    } catch (e) {
      await smartLogger(e, this.notificationService);
    }
  }
}
