import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {firstValueFrom} from 'rxjs';
import {SupplyCategoryModel} from '../models/supply-category.model';

@Injectable({
  providedIn: 'root'
})
export class SupplyCategoriesService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/SupplyCategories/`;
    return await firstValueFrom(this.httpClient.get<Array<SupplyCategoryModel>>(url));
  }

  async postOne(supplyCategory: SupplyCategoryModel) {
    const url = `${environment.apiBaseEndpoint}/SupplyCategories/`;
    return await firstValueFrom(this.httpClient.post<SupplyCategoryModel>(url, supplyCategory));
  }

  async putOne(supplyCategory: SupplyCategoryModel) {
    const url = `${environment.apiBaseEndpoint}/SupplyCategories/`;
    return await firstValueFrom(this.httpClient.put<SupplyCategoryModel>(url, supplyCategory));
  }

  async deleteOne(supplyCategoryId: number) {
    const url = `${environment.apiBaseEndpoint}/SupplyCategories/${supplyCategoryId}/`;
    return await firstValueFrom(this.httpClient.delete(url));
  }
}
