import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UnitModel} from '../models/unit.model';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/Units/`;
    return await firstValueFrom(this.httpClient.get<Array<UnitModel>>(url));
  }
}
