import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ItemCategoryModel} from '../models/item-category.model';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemCategoriesService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/ItemCategories/`;
    return await firstValueFrom(this.httpClient.get<Array<ItemCategoryModel>>(url));
  }

  async getOne(idItemGroup: number) {
    const url = `${environment.apiBaseEndpoint}/ItemCategories/${idItemGroup}/`;
    return await firstValueFrom(this.httpClient.get<ItemCategoryModel>(url));
  }

  async postOne(documentLineGroup: ItemCategoryModel) {
    const url = `${environment.apiBaseEndpoint}/ItemCategories/`;
    return await firstValueFrom(this.httpClient.post<ItemCategoryModel>(url, documentLineGroup));
  }

  async putOne(documentLineGroup: ItemCategoryModel) {
    const url = `${environment.apiBaseEndpoint}/ItemCategories/`;
    return await firstValueFrom(this.httpClient.put<ItemCategoryModel>(url, documentLineGroup));
  }

  async deleteOne(idItemGroup: number) {
    const url = `${environment.apiBaseEndpoint}/ItemCategories/${idItemGroup}/`;
    return await firstValueFrom(this.httpClient.delete(url));
  }
}
