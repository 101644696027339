import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettingsModel} from '../models/app-settings.model';
import {environment} from '../../environments/environment';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/AppSettings/`;
    return await firstValueFrom(this.httpClient.get<AppSettingsModel>(url));
  }

  async putOne(settings: AppSettingsModel) {
    const url = `${environment.apiBaseEndpoint}/AppSettings/`;
    return await firstValueFrom(this.httpClient.put(url, settings));
  }

  async uploadLogo(logo: any) {
    const formData = new FormData();
    formData.append('logo', logo);
    const url = `${environment.apiBaseEndpoint}/AppSettings/Logo/`;
    return await firstValueFrom(this.httpClient.post(url, formData));
  }

  async downloadLogo() {
    const url = `${environment.apiBaseEndpoint}/AppSettings/Logo/`;
    return await firstValueFrom(this.httpClient.get(url, {observe: 'response', responseType: 'blob'}));
  }
}
