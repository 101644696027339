import {NzNotificationService} from 'ng-zorro-antd/notification';
import {HttpErrorResponse} from '@angular/common/http';

const warning = `<b>Cette erreur doit être rapportée à l'administrateur pour résolution</b>`;

export default async function smartLogger(unknownError: any, notificationService: NzNotificationService) {
  console.error(unknownError);

  if (unknownError instanceof HttpErrorResponse) {
    const httpErrorResponse = unknownError as HttpErrorResponse;

    switch (true) {
      case httpErrorResponse.status === 400:

        const validationErrors: { errors: object } = httpErrorResponse.error;

        notificationService.warning(
          'Erreur de validation',
          `<b>${Object.values(validationErrors.errors)[0][0]}</b>`
        );

        break;

      case httpErrorResponse.status === 401:

        notificationService.warning(
          'Accès interdit',
          `<b>Vous devez être authentifié pour exécuter cette action.</b>`
        );

        break;

      case httpErrorResponse.status === 403:

        notificationService.warning(
          'Accès interdit',
          `<b>Vous n'avez pas la permission d'effectuer cette action.</b>`
        );

        break;

      case  httpErrorResponse.status === 499:

        if (httpErrorResponse.error instanceof Blob) {
          notificationService.warning(
            'Erreur d\'opération',
            `<b>${await httpErrorResponse.error.text()}</b>`
          );
        } else {
          notificationService.warning(
            'Erreur d\'opération',
            `<b>${httpErrorResponse.error}</b>`
          );
        }

        break;

      default:

        notificationService.error(
          'Erreur serveur non gérée',
          warning
        );

        throw httpErrorResponse;
    }
  } else {
    notificationService.error(
      'Erreur interne non gérée',
      warning
    );

    throw unknownError;
  }
}
