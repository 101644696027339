import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CustomerTypeModel} from '../models/customer-type.model';
import {environment} from '../../environments/environment';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerTypesService {
  constructor(private httpClient: HttpClient) {
  }

  async getAll() {
    const url = `${environment.apiBaseEndpoint}/CustomerTypes/`;
    return await firstValueFrom(this.httpClient.get<Array<CustomerTypeModel>>(url));
  }

  async postOne(customerType: CustomerTypeModel) {
    const url = `${environment.apiBaseEndpoint}/CustomerTypes/`;
    return await firstValueFrom(this.httpClient.post<CustomerTypeModel>(url, customerType));
  }

  async putOne(customerType: CustomerTypeModel) {
    const url = `${environment.apiBaseEndpoint}/CustomerTypes/`;
    return await firstValueFrom(this.httpClient.put<CustomerTypeModel>(url, customerType));
  }

  async deleteOne(customerTypeId: number) {
    const url = `${environment.apiBaseEndpoint}/CustomerTypes/${customerTypeId}/`;
    return await firstValueFrom(this.httpClient.delete(url));
  }
}
